<template>
  <div class="upload-container">
    <el-button
      :style="{ background: color, borderColor: color }"
      icon="el-icon-upload"
      size="mini"
      type="primary"
      @click="dialogVisible = true"
    >
      上传
    </el-button>
    <el-dialog
      :visible.sync="dialogVisible"
      @close="handleClose"
      append-to-body
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        action=""
        :show-file-list="false"
        multiple
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-table v-show="showFiles" :data="uploadFilesList" style="width: 100%">
        <el-table-column prop="name" :show-overflow-tooltip="true" label="名称">
          <template slot-scope="scope">
            <i style="color: #409eff" class="el-icon-s-order" />{{
              scope.row.name
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="是否成功" width="300">
          <template slot-scope="scope">
            <template v-if="scope.row.status === 'success'"
              >上传成功！</template
            >
            <template v-else-if="scope.row.status === 'error'"
              >上传失败!</template
            >
            <el-progress v-else :percentage="scope.row.progress" />
          </template>
        </el-table-column>
        <el-table-column width="200" label="上传进度">
          <template slot-scope="scope">
            {{ Uploaded(scope.row.size, scope.row.progress) }} /
            {{ ByteConversion(scope.row.size) }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false"> 取消 </el-button>
        <el-button type="primary" @click="handleSubmit"> 确认 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      // showProgress: false,
      fileList: [],
      showFiles: false,
      uploadFilesList: [],
    };
  },
  methods: {
    beforeUpload(file) {
      this.showFiles = true;
      const fileList = {};
      for (const key in file) {
        fileList[key] = file[key];
      }
      // status:uploading、success、error 文件上传状态
      // progress 文件上传进度
      this.uploadFilesList.push({
        ...fileList,
        progress: 0,
        status: "uploading",
      });
      this.httpRequest(file, (parms) => {
        this.showProgress(fileList, parms);
      });
      // 阻止 el-upload的默认上传
      return false;
    },
    showProgress(file, parms) {
      const { progress, status } = parms;
      const arr = [...this.uploadFilesList].map((items) => {
        if (items.uid === file.uid) {
          items.progress = progress;
          items.status = status;
        }
        return items;
      });
      this.uploadFilesList = [...arr];
    },
    httpRequest(file, callback) {
      let params = new FormData();
      params.append("file", file);
      let progress = 0;
      this.axios({
        method: "post",
        url: "/pms/v1/api/cust/system/file/upload",
        data: params,
        onUploadProgress: (progressEvent) => {
          // 获取文件上传进度 axios自带的
          progress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          callback({ progress, status: "uploading" });
        },
      })
        .then((res) => {
          // 成功状态
          if (res.data.code == 0) {
            this.fileList.push(res.data.data);
            callback({ progress, status: "success" });
          } else {
            callback({ progress, status: "error" });
          }
        })
        .catch(() => {
          // 失败状态
          callback({ progress, status: "error" });
        });
    },
    Uploaded(size, percent) {
      return this.ByteConversion((size * percent) / 100);
    },
    ByteConversion(value) {
      if (null == value || value == "") {
        return "0 B";
      }
      var unitArr = new Array("B", "KB", "MB", "GB");
      var index = 0,
        srcsize = parseFloat(value);
      index = Math.floor(Math.log(srcsize) / Math.log(1024));
      var size = srcsize / Math.pow(1024, index);
      //  保留的小数位数
      size = size.toFixed(2);
      return size + unitArr[index];
    },
    handleClose() {
      this.fileList = [];
      this.uploadFilesList = [];
      // this.$refs.upload.clearFiles();
    },
    handleSubmit() {
      this.$emit("successCBK", this.fileList);
      // this.$refs.upload.clearFiles();
      this.showFiles = false;
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-demo {
  margin-bottom: 20px;
  ::v-deep {
    .el-upload--text {
      width: 100%;
    }
    .el-upload-dragger {
      width: 100%;
    }
  }
}
</style>
