<template>
  <div class="content workflow">
    <p class="name">SDK文档资料</p>
    <div class="top">
      <el-input
        v-model="queryinfo.name"
        placeholder="模糊查询项目名"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-input
        v-model="queryinfo.chipname"
        placeholder="模糊查询芯片型号"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-input
        v-model="queryinfo.type"
        placeholder="模糊查询类型"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-input
        v-model="queryinfo.module"
        placeholder="模糊查询模块"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-date-picker
        v-model="queryinfo.time"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="changePage"
      >
      </el-date-picker>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        @click="changePage"
        >查询</el-button
      >
    </div>
    <el-skeleton :loading="loading" animated>
      <el-card
        shadow="never"
        class="project pro"
        v-for="(item, index) in projectlist"
        :key="index"
      >
        <div>
          <p class="header">
            <el-button class="title" type="text" @click="showSub(item.id)">
              {{ item.name }}——{{ item.title }}
            </el-button>
            <span class="time">{{ item.updateTime }}</span>
          </p>
          <!-- <el-tag>{{ item.cname }}</el-tag> -->
          <el-tag>{{ item.chipname }}</el-tag>
          <el-tag type="success">类型：{{ item.type }}</el-tag>
          <el-tag type="warning">模块：{{ item.module }}</el-tag>
          <div v-html="item.content"></div>
        </div>
      </el-card>
      <el-empty
        description="暂无项目"
        v-show="projectlist.length == 0"
      ></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :pager-count="5"
        :page-sizes="[10, 15, 20, 25, 30]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        name: "",
        chipname: "",
        type: "",
        module: "",
        time: [],
        //当前的页数
        pagenum: 1,
        //当前每页显示多少数据
        pagesize: 10,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      total: 0,
      projectlist: [],
      loading: true,
    };
  },
  created() {
    if (sessionStorage.getItem("project_query")) {
      this.queryinfo = JSON.parse(sessionStorage.getItem("project_query"));
      sessionStorage.removeItem("project_query");
    } else {
      var start = new Date();
      start.setMonth(start.getMonth() - 12);
      start = this.getDateString(start);
      var end = this.getDateString(new Date());
      this.queryinfo.time = [start, end];
    }
    this.getProjectPage();
  },
  methods: {
    getDateString(date) {
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getProjectPage();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getProjectPage();
    },
    changePage() {
      this.queryinfo.pagenum = 1;
      this.getProjectPage();
    },
    getProjectPage() {
      var query = this.$sale.getProjectPage(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/knowledge/project/main/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.projectlist = data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    showSub(id) {
      sessionStorage.setItem("project_query", JSON.stringify(this.queryinfo));
      this.$router.push({
        path: "/konwl/project/sub",
        query: { id: id },
      });
    },
  },
};
</script>

<style scoped>
.project .header .time {
  /* font-size: 13px;
  color: #c0c4cc; */
  margin-left: 15px;
}
</style>