import axios from 'axios'
import router from '../router'
import domMessage from './messageOnce'

// new 对象实例
const messageOnce = new domMessage()

axios.defaults.headers.get['Content-Type'] = 'application/json'

//请求拦截
axios.interceptors.request.use(
  config =>{
    config.headers['Content-Type'] = 'application/json'
    const token = localStorage.getItem('cust_token');
    if(token != null){
      try {
        config.headers.access_token = token;
      } catch (error) {
      }
    }
    return config;
  },
  error =>{
    //console.log(error)
    return Promise.reject(error);
  }
)

//响应拦截器
axios.interceptors.response.use(
  response =>{
    var msg =response.data.msg
    if (msg=='Token 已过期') {
      messageOnce.warning({
        message: msg,
        type: 'warning'
      })
      localStorage.removeItem("cust_token")
      router.push('/login')
      return Promise.reject(response);
    }
    return response;
  },
  error =>{
    var code = error.response.data.code;
    if(code == 401){
      //鉴权失败，跳转到首页
      localStorage.removeItem("cust_token")
      router.push('/login')
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
)

export default axios