<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="loglist"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="ipaddress" label="IP地址"> </el-table-column>
      <el-table-column prop="operTime" label="登录时间"> </el-table-column>
      <el-table-column prop="operType" label="操作类型"> </el-table-column>
      <el-table-column prop="explain" label="备注">
        <template v-slot="scope">
          <el-tag v-if="scope.row.explain == '登录成功'" type="success"
            >登录成功</el-tag
          >
          <el-tag v-else-if="scope.row.explain == '登录失败'" type="danger"
            >登录失败</el-tag
          >
          <el-tag v-else>{{ scope.row.explain }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryinfo.pagenum"
      :pager-count="5"
      :page-sizes="[8, 10, 15, 20, 25, 30]"
      :page-size="queryinfo.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        pagenum: 1,
        pagesize: 8,
      },
      total: 0,
      loglist: [],
      loading: true,
    };
  },
  created() {
    this.getCustLog();
  },
  methods: {
    getCustLog() {
      var query = this.$sale.getLogQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/cust/staffoper/page", query)
        .then((res) => {
          if (res.data.code == 0) {
            var data = res.data.data;
            this.loglist = data.records;
            this.total = data.total;
            this.loading = false;
          }
        });
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getCustLog();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getCustLog();
    },
  },
};
</script>

<style scoped>
.el-table {
  margin-top: 20px;
}
</style>