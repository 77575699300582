<template>
  <div class="content">
    <el-card shadow="never">
      <el-page-header @back="goBack" content="详情页面"> </el-page-header>
      <el-button type="text" @click="download" style="float: right">
        下载表单<i class="el-icon-download"></i>
      </el-button>
      <div class="production">
        <div class="image">
          <img src="../../assets/images/product_top.png" />
        </div>
        <p class="title">生产质量分析报告</p>
        <div class="main">
          <span class="left">一、事故信息</span>
          <span
            v-html="
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            "
          ></span>
          <span class="right">反馈日期：{{ main.createTime }}</span>
          <table border="1">
            <tbody>
              <tr>
                <td>客户名称</td>
                <td>{{ main.cid }}</td>
                <td>芯片型号</td>
                <td>{{ main.chipname }}</td>
                <td>封装形式</td>
                <td>{{ main.pkgtype }}</td>
              </tr>
              <tr>
                <td>订单总数</td>
                <td>{{ addComma(main.orderCount) }}</td>
                <td>生产数量</td>
                <td>{{ addComma(main.productionCount) }}</td>
                <td>不良数量</td>
                <td>{{ addComma(main.badCount) }}</td>
              </tr>
              <tr>
                <td>不 良 率</td>
                <td>
                  {{ main.badRate }}
                  <span v-show="main.badRate != 0 && main.badRate">%</span>
                </td>
                <td>样本数量</td>
                <td>{{ addComma(main.sampleCount) }}</td>
                <td>校 验 码</td>
                <td>{{ main.checksum }}</td>
              </tr>
              <tr>
                <td>
                  <span>客户问题</span><br />
                  <span>现象描述</span>
                </td>
                <td class="main_content" colspan="5" v-html="main.content"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div class="subs">
          <span class="left">二、事故分析</span>
          <span
            v-html="
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            "
          ></span>
          <span class="right">反馈日期：{{ date }}</span>
          <table border="1">
            <tbody>
              <tr>
                <td class="subhead">
                  检测结果：（描述实际测试到的不良现象，比例等）
                </td>
              </tr>
              <tr>
                <td class="subcontent">
                  <p
                    v-for="(item, index) in result"
                    :key="index"
                    v-html="item.content"
                  ></p>
                </td>
              </tr>
              <tr>
                <td class="subhead">
                  原因分析：（从芯片，软件设计，硬件设计，生产流程等方面对不良原因进行分析）
                </td>
              </tr>
              <tr>
                <td class="subcontent">
                  <p
                    v-for="(item, index) in reason"
                    :key="index"
                    v-html="item.content"
                  ></p>
                </td>
              </tr>
              <tr>
                <td class="subhead">
                  解决方案：（根据原因分析，提出短期或长期解决方案，为共性问题时，需知会相关人员排查处理）
                </td>
              </tr>
              <template v-for="(item, index) in solution">
                <tr :key="item.subhead">
                  <td class="subhead">◆ {{ item.direction }}</td>
                </tr>
                <tr :key="index">
                  <td class="subcontent">
                    <div v-for="(item1, index) in item.content" :key="index">
                      <p v-html="item1"></p>
                      <p
                        style="
                          text-align: right;
                          margin: 0;
                          padding-right: 10px;
                        "
                      >
                        ——跟进部门：{{ item.deptname }}
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-if="solution.length == 0">
                <td class="subcontent"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
      </div>
    </el-card>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
export default {
  data() {
    return {
      main: {},
      date: "",
      result: [],
      reason: [],
      solution: [],
      users: [],
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getDetail() {
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/detail", {
          id: this.$route.query.id,
        })
        .then((res) => {
          if (res.data.code == "0") {
            var detail = res.data.data;
            this.main = this.modifyMain(detail.main);
            var subs = detail.subs;
            var time = [];
            for (let i = 0; i < subs.length; i++) {
              if (subs[i].createTime) {
                time.push(subs[i].createTime.split(" ")[0]);
              }
            }
            if (time.length > 0) {
              time.sort((num1, num2) => {
                return num1 - num2 < 0;
              });
              var date = time[0].split(" ")[0].split("-");
              this.date =
                date[0] + " 年 " + date[1] + " 月 " + date[2] + " 日 ";
            } else {
              this.date = "_____年____月____日";
            }
            for (let i = 0; i < subs.length; i++) {
              if (subs[i].type == "检测结果") {
                this.result.push(subs[i]);
              } else if (subs[i].type == "原因分析") {
                this.reason.push(subs[i]);
              } else if (subs[i].type.indexOf("解决方案") > -1) {
                this.solution.push(subs[i]);
              }
            }
            if (this.solution.length > 0) {
              for (let i = 0; i < this.solution.length; i++) {
                this.solution[i].direction =
                  this.solution[i].type.split("--")[1];
              }
            }
            this.solution = this.mergeObject(this.solution);
            // this.users = detail.users;
          }
        });
    },
    modifyMain(main) {
      this.axios.post("/pms/v1/api/cust/cust/company/info").then((res) => {
        var company = res.data.data;
        main.cid = company.name;
      });
      var time = main.createTime.split(" ")[0].split("-");
      main.createTime = time[0] + " 年 " + time[1] + " 月 " + time[2] + " 日 ";
      return main;
    },
    mergeObject(array) {
      var arrayFilted = [];
      array.forEach(function (value, key) {
        //判断过滤后的数组是否为空
        if (arrayFilted.length == 0) {
          arrayFilted.push(value);
        } else {
          arrayFilted.forEach(function (valueIndex, keyIndex) {
            if (
              valueIndex.direction &&
              valueIndex.direction !== value.direction
            ) {
              arrayFilted.push(value);
            } else if (
              valueIndex.direction &&
              valueIndex.direction === value.direction
            ) {
              valueIndex.content = valueIndex.content + "<br/>" + value.content;
            }
          });
        }
      });
      for (let i = 0; i < arrayFilted.length; i++) {
        arrayFilted[i].content = arrayFilted[i].content.split("<br/>");
      }
      return arrayFilted;
    },
    addComma(num) {
      if (num) {
        let reg =
          num.toString().indexOf(".") > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(\d{3})+$)/g;
        return num.toString().replace(reg, "$1,");
      } else {
        return 0;
      }
    },
    download() {
      // let contentHtml = document.getElementsByClassName("production")[0]
      //   .innerHTML;
      let contentDocument = document.getElementsByClassName("production")[0];
      this.getBase64(contentDocument);
      setTimeout(() => {
        let cssHTML = `
.image{
  width: 100%;
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  font-family: 'fangsong';
}

.left {
  float:left;
  font-weight: bold;
}

.right {
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td {
  height: 35px;
}

.main,
.subs,
.users {
  font-size: 16px;
  font-family: '宋体';
}

.main td,
.subs td,
.users td {
  font-size: 14px;
  font-family: '宋体';
}

.main td {
  width: 16.6667%;
  text-align: center;
}

.main .main_content{
  text-align: left;
}

.subs td {
  text-align: left;
}

.subs .subhead {
  font-weight: bold;
}

.subs .subcontent {
  height: 100px;
}

.subs .subcontent p {
  text-indent: 15px;
}

.users td {
  text-align: center;
}

.footer p {
  font-weight: bold;
  text-align: left;
  text-indent: 2em;
}

.main .main_content p,
.subs .subcontent p{
  margin: 3px 0;
}
    `;
        let content = `<!DOCTYPE html><html>
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                <style>
                    ${cssHTML}
                </style>
            </head>
            <body>
                ${contentDocument.innerHTML}
            </body>
            </html>`;
        let converted = htmlDocx.asBlob(content);
        FileSaver.saveAs(converted, "生产质量分析报告.docx");
      }, 500);
    },
    getBase64(contentDocument) {
      let imgs = contentDocument.querySelectorAll("img");
      imgs.forEach((img, i) => {
        let Img = new Image(),
          dataURL = "";
        Img.src = img.src;
        Img.setAttribute("crossOrigin", "Anonymous");
        Img.onload = function () {
          let canvas = document.createElement("canvas"),
            width = Img.width,
            height = Img.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
          dataURL = canvas.toDataURL();
          img.setAttribute("src", dataURL);
        };
      });
    },
  },
};
</script>

<style scoped>
.el-card {
  padding: 0 100px;
}

.product {
  color: black;
}

.title {
  font-weight: bold;
  font-size: 24px;
  font-family: "fangsong";
}

.left {
  float: left;
  font-weight: bold;
}

.right {
  float: right;
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td {
  height: 35px;
}

.main,
.subs,
.users {
  font-size: 16px;
  font-family: '宋体';
}

.main td {
  width: 16.6667%;
  text-align: center;
}

.main .main_content {
  text-align: left;
}

.subs td {
  text-align: left;
}

.subs .subhead {
  font-weight: bold;
}

.subs .subcontent {
  height: 100px;
}

.subs .subcontent p {
  text-indent: 2em;
}

.footer p {
  font-weight: bold;
  text-align: left;
  text-indent: 2em;
}
</style>
<style>
.main .main_content p,
.subs .subcontent p {
  margin: 3px 0;
}
</style>