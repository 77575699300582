<template>
  <div class="content problem workflow">
    <p class="name">开发调试问题</p>
    <div class="top select">
      <el-button
        type="success"
        size="medium"
        icon="el-icon-edit"
        @click="createProblem()"
        >创建</el-button
      >
      <el-input
        v-model="queryinfo.order"
        placeholder="模糊查询订单"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-select
        class="status"
        v-model="queryinfo.status"
        @change="changePage"
        collapse-tags
        placeholder="请选择状态"
      >
        <el-option
          v-for="item in statuslist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        class="type"
        v-model="queryinfo.type"
        clearable
        filterable
        @change="changePage"
        collapse-tags
        placeholder="请选择问题途径"
      >
        <el-option
          v-for="item in typelist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="queryinfo.time"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="changePage"
      >
      </el-date-picker>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        @click="changePage"
        >查询</el-button
      >
    </div>
    <el-skeleton :loading="loading" animated>
      <el-card
        shadow="never"
        class="pro"
        v-for="(item, index) in problemlist"
        :key="index"
      >
        <div>
          <p class="header">
            <el-button class="title" type="text">
              {{ item.orderNumber }}——{{ item.title }}
            </el-button>
            <el-tag v-if="item.status == -1" type="info">取消</el-tag>
            <el-tag v-if="item.status == 1" type="warning">制表</el-tag>
            <el-tag v-if="item.status == 2">跟进中</el-tag>
            <el-tag v-if="item.status == 6" type="danger">延后处理</el-tag>
            <el-tag v-if="item.status == 10" type="success">完成</el-tag>
            <span class="time">{{ item.updateTime }}</span>
            <span class="btns">
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑"
                placement="top"
              >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showEditDialog(item.id)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="处理进度"
                placement="top"
              >
                <el-button
                  type="warning"
                  icon="el-icon-tickets"
                  size="mini"
                  @click="sub(item.id)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="日志"
                placement="top"
              >
                <el-button
                  type="success"
                  icon="el-icon-document"
                  size="mini"
                  @click="wcpLog(item.id)"
                ></el-button>
              </el-tooltip>
            </span>
          </p>
          <!-- <el-tag>{{ item.cname }}</el-tag> -->
          <el-tag>芯片型号：{{ item.chipname }}</el-tag>
          <!-- <el-tag type="success">{{ item.wafername }}</el-tag>
        <el-tag type="warning">{{ item.productline }}</el-tag>
        <el-tag type="danger">{{ item.pkgtype }}</el-tag> -->
          <el-tag
            type="success"
            v-if="item.type == '线上'"
            v-show="item.type"
            >{{ item.type }}</el-tag
          >
          <el-tag
            type="warning"
            v-else-if="item.type == '来访'"
            v-show="item.type"
            >{{ item.type }}</el-tag
          >
          <div class="ordercontent" v-html="item.content"></div>
          <span class="btns footer">
            <el-button
              v-show="item.status == 1"
              type="success"
              icon="el-icon-upload2"
              size="small"
              @click="submit(item.id)"
              >提交</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-close"
              size="small"
              v-show="item.status == 1"
              @click="cancel(item.id)"
              >取消</el-button
            >
          </span>
        </div>
      </el-card>
      <el-empty
        description="暂无数据"
        v-show="problemlist.length == 0"
      ></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :pager-count="5"
        :page-sizes="[10, 15, 20, 25, 30]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-skeleton>

    <el-dialog
      class="order"
      title="创建订单"
      :visible.sync="addDialogVisible"
      width="60%"
      style="margin-top: -8vh"
      @close="addDialogClosed"
      :close-on-click-modal="false"
    >
      <!-- 内容主题区域 -->
      <el-form
        size="small"
        class="order"
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="90px"
      >
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="订单类型:" prop="type">
              <el-select
                v-model="addForm.type"
                clearable
                placeholder="请选择问题途径"
              >
                <el-option
                  v-for="item in typelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品型号:" prop="chipname">
              <el-select
                v-model="addForm.chipname"
                clearable
                filterable
                placeholder="请选择产品型号"
                @change="getBomInfo(addForm)"
              >
                <el-option
                  v-for="item in chiplist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="晶圆型号:" prop="wafername">
              <el-input v-model="addForm.wafername"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="产品线:" prop="productline">
              <el-input v-model="addForm.productline"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="封装形式:" prop="pkgtype">
              <el-input v-model="addForm.pkgtype"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7"> </el-col>
        </el-row>
        <el-form-item label="标题摘要:" prop="title">
          <el-input v-model="addForm.title" class="title"></el-input>
        </el-form-item>
        <el-form-item label="问题描述:" prop="content">
          <tinymce
            v-model="addForm.content"
            ref="addcontent"
            :height="400"
          ></tinymce>
        </el-form-item>
        <el-form-item label="钉钉群:" prop="dingtalk">
          <el-input v-model="addForm.dingtalk"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <div class="checkbox"></div>
        <el-checkbox v-model="checked" :disabled="addForm.dingtalk == ''"
          >是否推送到钉钉群</el-checkbox
        >
        <el-checkbox
          v-model="textinfo.isAtall"
          :disabled="!checked || addForm.dingtalk == ''"
          >是否@全体成员</el-checkbox
        >
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addProblem()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="order"
      :title="'修改订单《' + editForm.orderNumber + '》'"
      :visible.sync="editDialogVisible"
      width="60%"
      style="margin-top: -8vh"
      @close="editDialogClosed"
      :close-on-click-modal="false"
    >
      <!-- 内容主题区域 -->
      <el-form
        size="small"
        class="order"
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="90px"
      >
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="订单类型:" prop="type">
              <el-select
                v-model="editForm.type"
                clearable
                placeholder="请选择问题途径"
              >
                <el-option
                  v-for="item in typelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品型号:" prop="chipname">
              <el-select
                v-model="editForm.chipname"
                clearable
                filterable
                placeholder="请选择产品型号"
                @change="getBomInfo(editForm)"
              >
                <el-option
                  v-for="item in chiplist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="晶圆型号:" prop="wafername">
              <el-input v-model="editForm.wafername"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="产品线:" prop="productline">
              <el-input v-model="editForm.productline"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="封装形式:" prop="pkgtype">
              <el-input v-model="editForm.pkgtype"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7"> </el-col>
        </el-row>
        <el-form-item label="标题摘要:" prop="title">
          <el-input v-model="editForm.title" class="title"></el-input>
        </el-form-item>
        <el-form-item label="问题描述:" prop="content">
          <tinymce
            v-if="isRefresh"
            v-model="editForm.content"
            :height="400"
          ></tinymce>
        </el-form-item>
        <el-form-item label="钉钉群:" prop="dingtalk">
          <el-input v-model="editForm.dingtalk"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" :disabled="editForm.dingtalk == ''"
          >是否推送到钉钉群</el-checkbox
        >
        <el-checkbox
          v-model="textinfo.isAtall"
          :disabled="!checked || editForm.dingtalk == ''"
          >是否@全体成员</el-checkbox
        >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editProblem()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="订单日志"
      :visible.sync="logVisible"
      width="30%"
      style="margin-top: -4vh"
      :close-on-click-modal="false"
    >
      <el-timeline reverse>
        <el-timeline-item
          v-for="(item, index) in loglist"
          :key="index"
          :timestamp="item.logTime"
          placement="top"
        >
          <p v-if="item.deptname">
            {{ item.deptname }}--{{ item.realname }}：{{ item.explain }}
          </p>
          <p v-else>{{ item.realname }}：{{ item.explain }}</p>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <el-dialog
      class="order"
      title="处理进度"
      :visible.sync="subVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-table
        class="subs"
        border
        :data="sublist"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="type" width="100" label="类型">
        </el-table-column>
        <el-table-column prop="content" label="内容">
          <template v-slot="scope">
            <span v-html="scope.row.content" class="subcontent"></span>
          </template>
        </el-table-column>
        <el-table-column prop="realname" width="100" label="操作人">
        </el-table-column>
        <el-table-column prop="deptname" width="120" label="部门名称">
        </el-table-column>
        <el-table-column prop="createTime" width="200" label="创建时间">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import tinymce from "@/components/Tinymce";
export default {
  components: {
    tinymce,
  },
  data() {
    return {
      queryinfo: {
        order: "",
        status: "all",
        type: "",
        time: [],
        //当前的页数
        pagenum: 1,
        //当前每页显示多少数据
        pagesize: 10,
      },
      statuslist: [
        {
          value: "all",
          label: "所有（不包含取消）",
        },
        {
          value: -1,
          label: "取消",
        },
        {
          value: 1,
          label: "填写",
        },
        {
          value: 2,
          label: "跟进中",
        },
        {
          value: 6,
          label: "延后处理",
        },
        {
          value: 10,
          label: "完成",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      chiplist: [],
      total: 0,
      loading: true,
      problemlist: [],
      typelist: [
        {
          label: "线上",
          value: "线上",
        },
        {
          label: "来访",
          value: "来访",
        },
      ],
      addDialogVisible: false,
      addForm: {
        cid: "",
        type: "",
        orderNumber: "",
        wafername: "",
        productline: "",
        chipname: "",
        pkgtype: "",
        flowUserid: "",
        title: "",
        content: "",
        dingtalk: "",
        workflowTime: this.getDateString(new Date()) + " 00:00:00",
      },
      addFormRules: {
        chipname: [
          {
            required: true,
            message: "请选择产品型号",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题摘要",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {},
      editFormRules: {
        chipname: [
          {
            required: true,
            message: "请选择产品型号",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题摘要",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: "blur",
          },
        ],
      },
      isRefresh: true,
      checked: true,
      textinfo: {
        content: "",
        isAtall: true,
        dingtalkAccessToken: "",
      },
      logVisible: false,
      loglist: [],
      subVisible: false,
      sublist: [],
    };
  },
  created() {
    var start = new Date();
    start.setMonth(start.getMonth() - 6);
    start = this.getDateString(start);
    var end = this.getDateString(new Date());
    this.queryinfo.time = [start, end];
    this.getProblemPage();
  },
  methods: {
    getDateString(date) {
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getProblemPage();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getProblemPage();
    },
    changePage() {
      this.queryinfo.pagenum = 1;
      this.getProblemPage();
    },
    getProblemPage() {
      var query = this.$sale.getFilterQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/order/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.axios
              .post("/pms/v1/api/cust/cust/company/info")
              .then((ret) => {
                var company = ret.data.data;
                data.forEach((item) => {
                  item.cname = company.name;
                });
                this.problemlist = data;
                this.total = res.data.data.total;
                this.loading = false;
              });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getChipList() {
      var query = this.$sale.getListQuery();
      this.axios
        .post("/pms/v1/api/cust/bom/chipname/list", query)
        .then((res) => {
          if (res.data.code == "0") {
            console.log(res);
            var data = res.data.data;
            this.chiplist = [];
            for (var i = 0; i < data.length; i++) {
              this.chiplist.push({ label: "", value: "" });
              this.chiplist[i].label = data[i].chipname;
              this.chiplist[i].value = data[i].id;
            }
          }
        });
    },
    addComma(num) {
      if (num) {
        let reg =
          num.toString().indexOf(".") > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(\d{3})+$)/g;
        return num.toString().replace(reg, "$1,");
      } else {
        return 0;
      }
    },
    createProblem() {
      this.getChipList();
      this.addDialogVisible = true;
    },
    getBomInfo(form) {
      if (form.chipname == "") {
        form.wafername = "";
        form.productline = "";
        form.pkgtype = "";
      } else {
        this.axios
          .post("/pms/v1/api/cust/bom/chipname/one", {
            id: form.chipname,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == "0") {
              var data = res.data.data;
              form.chipname = data.chipname;
              form.wafername = data.wafername;
              form.productline = data.productline;
              form.pkgtype = data.pkgtype;
            }
          });
      }
    },
    badRate(form) {
      if (
        form.problemCount != "" &&
        form.badCount != "" &&
        form.problemCount != "0"
      ) {
        var badRate = form.badCount / form.problemCount;
        form.badRate = badRate.toFixed(2);
      } else {
        form.badRate = 0;
        form.badCount = 0;
      }
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
      this.$refs.addcontent.setContent("");
    },
    addProblem() {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/pms/v1/api/cust/workflow/problem/order/create",
              this.addForm
            )
            .then((res) => {
              if (res.data.data) {
                this.getProblemPage();
                this.$message.success("创建成功");
                if (this.checked && this.addForm.dingtalk != "") {
                  this.sendText(
                    "创建了新的生产质量分析订单《" + this.addForm.title + "》",
                    this.addForm.dingtalk
                  );
                }
                this.addDialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    cancel(id) {
      this.$confirm("此操作将取消该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .post("/pms/v1/api/cust/workflow/problem/order/cancel", {
            id: id,
          })
          .then((res) => {
            if (res.data.data && res.data.code == 0) {
              this.getProblemPage();
              this.statusVisible = false;
              this.$message.success("取消成功");
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },
    submit(id) {
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/order/submit", {
          id: id,
        })
        .then((res) => {
          if (res.data.data && res.data.code == 0) {
            this.getProblemPage();
            this.statusVisible = false;
            this.$message.success("提交成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
      // this.$refs.editcontent.setContent("");
    },
    showEditDialog(id) {
      this.isRefresh = false;
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/order/one", {
          id: id,
        })
        .then((res) => {
          console.log(res);
          var data = res.data.data;
          this.editForm = data;
          this.getChipList();
          this.$nextTick(() => {
            this.isRefresh = true;
          });
          this.editDialogVisible = true;
        });
    },
    editProblem() {
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/pms/v1/api/cust/workflow/problem/order/update",
              this.editForm
            )
            .then((res) => {
              if (res.data.data) {
                this.getProblemPage();
                this.$message.success("更新成功");
                if (this.checked && this.editForm.dingtalk != "") {
                  this.sendText(
                    "更新了生产质量分析订单《" + this.editForm.title + "》",
                    this.editForm.dingtalk
                  );
                }
                this.editDialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    sendText(content, dingtalk) {
      this.axios.post("/pms/v1/api/cust/cust/company/info").then((res) => {
        this.textinfo.content = "客户【" + res.data.data.name + "】" + content;
        this.textinfo.dingtalkAccessToken = dingtalk;
        this.axios
          .post("/pms/v1/api/cust/system/dingtalk/sendtext", this.textinfo)
          .then((res) => {
            console.log(res);
          });
      });
    },
    wcpLog(id) {
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/log/list", { id: id })
        .then((res) => {
          if (res.data.code == 0) {
            this.loglist = res.data.data;
            this.logVisible = true;
          }
        });
    },
    sub(id) {
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/sub/list", { id: id })
        .then((res) => {
          if (res.data.code == "0") {
            console.log(res);
            var data = res.data.data;
            this.sublist = data;
            this.subVisible = true;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style scoped>
.subcontent{
  text-align: left;
}
</style>
<style>
.problem .ordercontent p {
  margin: 3px 0;
}

.problem .top .status .el-input {
  width: 180px;
}

.problem .top .type .el-input {
  width: 180px;
}
</style>