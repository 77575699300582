<template>
  <div :class="{ fullscreen: fullscreen }" class="tinymce-container">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <editorImage
        color="#1890ff"
        class="editor-upload-btn"
        @successCBK="imageSuccessCBK"
      />
    </div>
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import Vue from "vue";
import editorImage from "./components/EditorImage";
import plugins from "./plugins";
import toolbar from "./toolbar";
import load from "./dynamicLoadScript";

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
const tinymceCDN =
  "https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js";

export default {
  name: "Tinymce",
  components: { editorImage },
  props: {
    id: {
      type: String,
      default: function () {
        return (
          "vue-tinymce-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      },
    },
    value: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      default: "edit insert format",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: "en",
        zh: "zh_CN",
        es: "es_MX",
        ja: "ja",
      },
    };
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || "")
        );
      }
    },
  },
  mounted() {
    this.init();
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message);
          return;
        }
        this.initTinymce();
      });
    },
    initTinymce() {
      const _this = this;
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList["zh"],
        height: this.height,
        content_style: "img {max-width:100%} table {max-width:100%} body p {margin:3px 0}",
        body_class: "panel-body ",
        object_resizing: true,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        imagetools_cors_hosts: ["www.tinymce.com", "codepen.io"],
        default_link_target: "_blank",
        link_title: false,
        paste_data_images: true,
        images_upload_handler: function (blobInfo, success, failure) {
          let formdata = new FormData();
          formdata.set("file", blobInfo.blob());
          let v = new Vue();
          v.axios
            .post("/pms/v1/api/cust/system/file/upload", formdata)
            .then((res) => {
              // console.log(res);
              success(res.data.data.url);
            })
            .catch((res) => {
              failure("error");
            });
        },
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: (editor) => {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
        },
        setup(editor) {
          editor.on("FullscreenStateChanged", (e) => {
            _this.fullscreen = e.state;
          });
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img);
        //     $image.removeAttr('width');
        //     $image.removeAttr('height');
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image');
        //       $image.attr('data-wscnh', $image[0].height);
        //       $image.attr('data-wscnw', $image[0].width);
        //       $image.addClass('wscnph');
        //     }
        //   }, 0);
        //   return img
        // },
        // images_upload_handler(blobInfo, success, failure, progress) {
        //   progress(0);
        //   const token = _this.$store.getters.token;
        //   getToken(token).then(response => {
        //     const url = response.data.qiniu_url;
        //     const formData = new FormData();
        //     formData.append('token', response.data.qiniu_token);
        //     formData.append('key', response.data.qiniu_key);
        //     formData.append('file', blobInfo.blob(), url);
        //     upload(formData).then(() => {
        //       success(url);
        //       progress(100);
        //     })
        //   }).catch(err => {
        //     failure('出现未知问题，刷新页面，或者联系程序员')
        //     console.log(err);
        //   });
        // },
      });
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK(arr) {
      arr.forEach((v) => {
        // var suffix = this.matchFileSuffixType(v.filename);
        var type = v.contentType;
        if (type.indexOf("image") == 0) {
          window.tinymce
            .get(this.tinymceId)
            .insertContent(`<img class="wscnph" src="${v.url}"></img>`);
        } else {
          window.tinymce
            .get(this.tinymceId)
            .insertContent(
              `<a href="${v.url}" data-mce-href="${v.url}" style="color: #409eff;">${v.filename}</a>&nbsp;&nbsp;&nbsp;&nbsp;`
            );
        }
      });
    },
    // matchFileSuffixType(fileName) {
    //   // 后缀获取
    //   var suffix = "";
    //   // 获取类型结果
    //   var result = "";
    //   try {
    //     var flieArr = fileName.split(".");
    //     suffix = flieArr[flieArr.length - 1];
    //   } catch (err) {
    //     suffix = "";
    //   }
    //   // fileName无后缀返回 false
    //   if (!suffix) {
    //     result = false;
    //     return result;
    //   }
    //   // 图片格式
    //   var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
    //   // 进行图片匹配
    //   result = imglist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "image";
    //     return result;
    //   }
    //   // 匹配txt
    //   var txtlist = ["txt"];
    //   result = txtlist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "txt";
    //     return result;
    //   }
    //   // 匹配 excel
    //   var excelist = ["xls", "xlsx"];
    //   result = excelist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "excel";
    //     return result;
    //   }
    //   // 匹配 word
    //   var wordlist = ["doc", "docx"];
    //   result = wordlist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "word";
    //     return result;
    //   }
    //   // 匹配 pdf
    //   var pdflist = ["pdf"];
    //   result = pdflist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "pdf";
    //     return result;
    //   }
    //   // 匹配 ppt
    //   var pptlist = ["ppt"];
    //   result = pptlist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "ppt";
    //     return result;
    //   }
    //   // 匹配 视频
    //   var videolist = ["mp4", "m2v", "mkv"];
    //   result = videolist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "video";
    //     return result;
    //   }
    //   // 匹配 音频
    //   var radiolist = ["mp3", "wav", "wmv"];
    //   result = radiolist.some(function (item) {
    //     return item == suffix;
    //   });
    //   if (result) {
    //     result = "radio";
    //     return result;
    //   }
    //   // 其他 文件类型
    //   result = "other";
    //   return result;
    // },
  },
};
</script>

<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
  width: 100%;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
