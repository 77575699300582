<template>
  <div class="content">
    <el-card shadow="never">
      <el-tabs
        v-model="activeName"
        :tab-position="tabPosition"
        @tab-click="handleClick"
      >
        <el-tab-pane label="系统公告" name="1">
          <home></home>
        </el-tab-pane>
        <el-tab-pane label="系统通知" name="2">
          <company></company>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import home from "./noticehome";
import company from "./noticecompany";
export default {
  components: {
    home,
    company,
  },
  data() {
    return {
      activeName: "1",
      tabPosition: "left",
    };
  },
  created() {
    if (sessionStorage.getItem("activeNotice")) {
      this.activeName = sessionStorage.getItem("activeNotice");
    }
  },
  watch: {
    activeName(value){
        sessionStorage.setItem("activeNotice", value);
    }
  },
  methods: {
    handleClick(tab, event) {},
  },
};
</script>

<style scoped>
</style>