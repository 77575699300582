<template>
  <div class="content">
    <el-skeleton :loading="headerloading" animated class="header">
      <template slot="template">
        <el-skeleton-item
          variant="image"
          class="el-avatar"
          style="width: 120px; height: 120px"
        />
        <div class="el-descriptions">
          <el-skeleton-item variant="text" style="width: 30%" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
        </div>
      </template>
      <template>
        <el-avatar
          v-if="custinfo.realname"
          shape="square"
          style="background: #409eff"
          :size="120"
          >{{ custinfo.realname.substring(0, 1) }}</el-avatar
        >
        <el-avatar v-else icon="el-icon-user-solid"> </el-avatar>
        <el-descriptions title="个人信息">
          <el-descriptions-item label="用户名">
            {{ custinfo.username }}
          </el-descriptions-item>
          <el-descriptions-item label="真实姓名">
            {{ custinfo.realname }}
          </el-descriptions-item>
          <el-descriptions-item label="邮箱">
            {{ custinfo.email }}
          </el-descriptions-item>
          <el-descriptions-item label="手机号">
            {{ custinfo.mobile }}
          </el-descriptions-item>
          <el-descriptions-item label="所在公司" :span="2">
            {{ company.name }}
          </el-descriptions-item>
          <el-descriptions-item label="公司中文简称">
            {{ company.abbrCn }}
          </el-descriptions-item>
          <el-descriptions-item label="公司英文简称">
            {{ company.abbrEn }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </el-skeleton>

    <el-card class="info">
      <el-tabs>
        <el-tab-pane label="个人操作日志"><custlog></custlog></el-tab-pane>
        <el-tab-pane label="公司员工">
          <el-table
            ref="table"
            v-loading="loading"
            :data="stafflist"
            style="width: 100%"
            height="510px"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
          >
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="realname" label="真实姓名">
            </el-table-column>
            <el-table-column prop="email" label="邮箱"> </el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import custlog from "./log";
export default {
  components: {
    custlog,
  },
  data() {
    return {
      headerloading: true,
      custinfo: {},
      company: {},
      staffVisible: false,
      stafflist: [],
      loading: true,
    };
  },
  created() {
    this.getUserInfo();
    this.getStaffList();
  },
  methods: {
    getUserInfo() {
      this.axios.post("/pms/v1/api/cust/cust/staff/info").then((res) => {
        this.custinfo = res.data.data;
        this.getCompayInfo();
      });
    },
    getCompayInfo() {
      this.axios.post("/pms/v1/api/cust/cust/company/info").then((res) => {
        this.company = res.data.data;
        this.headerloading = false;
      });
    },
    getStaffList() {
      var query = this.$sale.getListQuery();
      this.axios
        .post("/pms/v1/api/cust/cust/company/liststaff", query)
        .then((res) => {
          if (res.data.code == 0) {
            var data = res.data.data;
            this.stafflist = data;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  text-align: left;
  display: -webkit-inline-box;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.el-avatar {
  margin: 20px;
  font-size: 50px;
}

.el-descriptions {
  margin: 20px;
  width: 1000px;
}

.info {
  margin-top: 15px;
  padding-bottom: 10px;
}
</style>
<style>
.header .el-descriptions__title {
  font-size: 20px;
}

.is-animated {
  display: -webkit-inline-box;
}

.el-skeleton__item{
  margin-top: 16px;
}
</style>