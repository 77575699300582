<template>
  <div class="content" style="height: 100%">
    <el-card class="projectsub" style="height: 100%">
      <el-page-header @back="goBack" content="文档资料查看下载">
      </el-page-header>
      <el-skeleton
        :loading="loading"
        animated
        style="height: 100%; margin-top: 20px"
      >
        <el-empty
          description="暂无文档资料"
          v-if="sublist.length == 0"
        ></el-empty>
        <el-container class="container" v-else>
          <el-aside width="250px">
            <!-- 侧边栏菜单 -->
            <el-menu
              background-color="#F7F7F7"
              text-color="#000033"
              active-text-color="#00cc00"
              unique-opened
              :default-active="activeItem"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in sublist"
                :key="item.id"
              >
                <template slot="title">
                  <span>{{ item.type }}</span>
                </template>
                <el-menu-item
                  v-for="sub in item.childs"
                  :key="sub.id"
                  :index="sub.id"
                  @click.native="ShowDetail(sub)"
                >
                  {{ sub.version }} ({{ sub.updateTime.split(" ")[0] }})
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-aside>
          <el-container>
            <div class="subdetail">
              <p class="title">{{ sub.title }}</p>
              <p class="time">发布时间：{{ sub.updateTime.split(" ")[0] }}</p>
              <p class="version">
                版本号：{{ sub.type }}--{{ sub.version }}
                <el-button type="text" @click="downloadFile(sub.id)"
                  >下载资料</el-button
                >
              </p>
              <div class="subcontent" v-html="sub.content"></div>
            </div>
          </el-container>
        </el-container>
      </el-skeleton>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      sublist: [],
      activeItem: "",
      sub: {},
    };
  },
  created() {
    this.getSubList();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getSubList() {
      var query = this.$sale.getProjectSubList();
      this.axios
        .post(
          "/pms/v1/api/cust/knowledge/project/sub/list?mainid=" +
            this.$route.query.id,
          query
        )
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data;
            if (data.length > 0) {
              this.activeItem = data[0].id;
              this.sub = data[0];
              const arr = [];
              data.forEach((item) => {
                const parent = arr.find((cur) => cur.type === item.type);
                if (parent) {
                  parent.childs.push(item);
                } else {
                  const obj = {
                    type: item.type,
                    childs: [item],
                  };
                  arr.push(obj);
                }
              });
              this.sublist = arr;
            }
            this.loading = false;
            // this.sublist = data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    ShowDetail(item) {
      this.activeItem = "";
      this.activeItem = item.id;
      this.sub = {};
      this.sub = item;
    },
    downloadFile(id) {
      this.axios
        .post("/pms/v1/api/cust/knowledge/project/file/createdownloadurl", {
          id: id,
        })
        .then((res) => {
          if (res.data.code == "0") {
            window.open(res.data.data);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
}

.el-aside {
  background-color: #f7f7f7;
  /* background-color: rgb(238, 241, 246); */
}

.el-aside .el-menu {
  border-right: none;
}

.el-aside .el-menu--inline > .el-menu-item {
  background-color: #eee !important;
}

.el-aside .el-menu--inline > .el-menu-item:hover {
  background-color: #c6c6c6 !important;
}

.container {
  height: 95%;
  background-color: #fdfcfc;
}

.subdetail {
  text-align: left;
  padding: 100px 150px;
  overflow: auto;
  width: 100%;
}

.subdetail .title {
  font-size: 40px;
  font-weight: bold;
}

.subdetail .el-button {
  padding-left: 10px;
}
</style>
<style>
.projectsub .el-card__body {
  height: 95%;
}
</style>