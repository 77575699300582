<template>
  <div class="login">
    <div class="login-box">
      <img src="@/assets/images/top.png" />
      <el-form
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
        class="login-form"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
            show-password
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        type: "cust",
        ts: new Date().getTime(),
      },
      loginFormRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      // dialogVisible: false,
    };
  },
  methods: {
    login() {
      // this.axios
      //   .post(
      //     "/pms/v1/api/auth/login?username=" +
      //       this.loginForm.username +
      //       "&password=" +
      //       this.loginForm.password +
      //       "&type=cust"
      //   )
      //   .then((res) => {
      //     var data = res.data;
      //     if (data.code == "0") {
      //       this.accessTokenCheck(data.data.access_token);
      //     } else {
      //       this.$message.error(data.msg);
      //     }
      //   });

      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          const _pubKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw1JZhjerkWJxGAqaUum8Tn7SQu6VYUpI4+rT/Z5Iu2/IIQdOaCnZSk7IjHL4MjV2RkZ4GHY33vmpDZNGaPROhmg62RXdkGS8pcXuCYZylDanRGZ1VoWjdTt410F0MNdkTSjWp9WRZNM9HDZY+vylTMJ9IyFgjizmk5KE2eTNceyavR5Y5f7SlaYFFlW4svnFUCSl42Ph8R0pyP59qWdZ51ElAJRCJ48405c5IbOGu4VGfIEsXcUGHx538yRA9i7tQw70165z7B9cabSrJ8IXlH/4prtWYokLXXFXE77gbNIpkQF8zNe+ZGtw1S7uQMX74XYr51gYCmZSk3XyXiUQnwIDAQAB
-----END PUBLIC KEY-----`;

          var encrypt = new JSEncrypt();
          encrypt.setPublicKey(_pubKey);
          var msg = JSON.stringify(this.loginForm);
          var en = encrypt.encrypt(msg);
          this.axios.post("/pms/v1/api/auth/secure/login", en).then((res) => {
            var data = res.data;
            if (data.code == "0") {
              this.accessTokenCheck(data.data.access_token);
            } else {
              var suffix = "@" + this.loginForm.username.split("@")[1];
              if (suffix == "@zh-jieli.com") {
                return this.$message.warning("公司员工请到员工登录页面");
              }
              this.$message.error(data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    accessTokenCheck(token) {
      this.axios
        .post("/pms/v1/api/auth/check?token=" + token + "")
        .then((res) => {
          var data = res.data.data;
          if (res.data.code == "0") {
            if (data.exp > data.iat) {
              localStorage.setItem("cust_token", token);
              this.$router.push("/index");
            } else {
              this.accessTokenRefresh(token);
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    accessTokenRefresh(token) {
      this.axios
        .post("/pms/v1/api/auth/refresh?token=" + token + "")
        .then((res) => {
          var data = res.data;
          if (data.code == "0") {
            localStorage.setItem("cust_token", data.data.access_token);
            this.$router.push("/index");
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
};
</script>

<style scoped>
.login {
  background: url("../assets/images/home_bg.png") 50% 0 no-repeat fixed;
  /* background: -webkit-radial-gradient(
    10% 20%,
    farthest-side circle,
    pink,
    #abcdef
  ); */
  height: 100%;
  width: 100%;
  position: absolute;
}

img {
  width: 70%;
}

.el-form {
  margin-top: 20px;
}

.login-box {
  width: 350px;
  height: 300px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.2);
}

.login-title {
  margin-top: 15px;
}

.login-box span {
  font-size: 26px;
  font-weight: 700;
  color: #2c3e50;
}

.login-form {
  position: absolute;
  top: 70px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.el-button {
  width: 100%;
}
</style>