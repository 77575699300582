<template>
  <div>
    <div class="content">
      <div class="home">
        <el-card shadow="never">
          <el-tabs v-model="activeName">
            <el-tab-pane label="生产质量问题" name="first">
              <el-skeleton :loading="wprloading" animated>
                <div v-for="(item, index) in wprlist" :key="index">
                  <a class="title" @click="showDetail(item.id)">
                    {{ item.orderNumber }}——{{ item.title }}
                  </a>
                  <el-tag v-if="item.status == -1" type="info">取消</el-tag>
                  <el-tag v-if="item.status == 1" type="warning">制表</el-tag>
                  <el-tag v-if="item.status == 2">跟进中</el-tag>
                  <el-tag v-if="item.status == 6" type="danger"
                    >延后处理</el-tag
                  >
                  <el-tag v-if="item.status == 10" type="success">完成</el-tag>
                  <span class="time" type="text">
                    {{ item.updateTime }}
                  </span>
                  <div class="info" v-html="item.content"></div>
                  <span>
                    <el-divider></el-divider>
                  </span>
                </div>
                <el-empty
                  description="暂无最新内容"
                  v-show="wprlist.length == 0"
                ></el-empty>
              </el-skeleton>
              <div class="more">
                <el-button type="text" @click="more('wpr')">
                  查看更多<i class="el-icon-d-arrow-right"></i>
                </el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="开发调试问题" name="second">
              <el-skeleton :loading="wcploading" animated>
                <div v-for="(item, index) in wcplist" :key="index">
                  <a class="title">
                    {{ item.orderNumber }}——{{ item.title }}
                  </a>
                  <el-tag v-if="item.status == -1" type="info">取消</el-tag>
                  <el-tag v-if="item.status == 1" type="warning">制表</el-tag>
                  <el-tag v-if="item.status == 2">跟进中</el-tag>
                  <el-tag v-if="item.status == 6" type="danger"
                    >延后处理</el-tag
                  >
                  <el-tag v-if="item.status == 10" type="success">完成</el-tag>
                  <span class="time" type="text">
                    {{ item.updateTime }}
                  </span>
                  <div class="info" v-html="item.content"></div>
                  <span>
                    <el-divider></el-divider>
                  </span>
                </div>
                <el-empty
                  description="暂无最新内容"
                  v-show="wcplist.length == 0"
                ></el-empty>
              </el-skeleton>
              <div class="more">
                <el-button type="text" @click="more('wcp')">
                  查看更多<i class="el-icon-d-arrow-right"></i>
                </el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="SDK文档资料" name="third">
              <el-skeleton :loading="projectloading" animated>
                <div v-for="(item, index) in projectlist" :key="index">
                  <a class="title" @click="showSub(item.id)">
                    {{ item.name }}——{{ item.title }}
                  </a>
                  <el-tag>{{ item.chipname }}</el-tag>
                  <el-tag type="success">类型：{{ item.type }}</el-tag>
                  <el-tag type="warning">模块：{{ item.module }}</el-tag>
                  <div class="info" v-html="item.content"></div>
                  <span>
                    <el-divider></el-divider>
                  </span>
                </div>
                <el-empty
                  description="暂无最新文档资料"
                  v-show="projectlist.length == 0"
                ></el-empty>
              </el-skeleton>
              <div class="more">
                <el-button type="text" @click="more('project')">
                  查看更多<i class="el-icon-d-arrow-right"></i>
                </el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
      <div class="other">
        <el-card shadow="never">
          <div slot="header" style="text-align: left; font-size: 14px">
            业务专区
          </div>
          <div class="icon">
            <el-row :gutter="20">
              <el-col :span="12">
                <div
                  class="icontitle icon-workflow"
                  @click="$router.push('/workflow/production')"
                >
                  <i class="el-icon-s-order"></i><br />
                  <span>生产质量问题</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="icontitle icon-workflow"
                  @click="$router.push('/workflow/problem')"
                >
                  <i class="el-icon-s-order"></i><br />
                  <span>开发调试问题</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card shadow="never">
          <div slot="header" style="text-align: left; font-size: 14px">
            资料专区
          </div>
          <div class="icon">
            <el-row :gutter="20">
              <el-col :span="12">
                <div
                  class="icontitle icon-konwl"
                  @click="$router.push('/konwl/project/main')"
                >
                  <i class="el-icon-news"></i><br />
                  <span>文档资料</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="icontitle icon-konwl" @click="$router.push('')">
                  <i class="el-icon-news"></i><br />
                  <span>培训资料</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card shadow="never">
          <div slot="header" style="text-align: left; font-size: 14px">
            <span>系统公告</span>
          </div>
          <el-skeleton :loading="notloading" animated :rows="2">
            <div
              v-for="(item, index) in noticelist"
              :key="index"
              style="text-align: left"
            >
              <span
                class="notice"
                v-if="item.priority == 'highlight'"
                style="color: #e6a23c"
                >{{ index + 1 }}、{{ item.content }}
              </span>
              <span class="notice" v-else>
                {{ index + 1 }}、{{ item.content }}
              </span>
              <span v-show="index < noticelist.length - 1">
                <el-divider></el-divider>
              </span>
              <!-- <el-alert
              v-if="item.priority == 'highlight'"
              type="warning"
              :closable="false"
              :title="item.title"
              :description="item.content"
            >
            </el-alert>
            <el-alert
              v-else
              type="success"
              :closable="false"
              :title="item.title"
              :description="item.content"
            >
            </el-alert> -->
            </div>
            <span class="noticenone" v-show="noticelist.length == 0"
              >暂无公告</span
            >
          </el-skeleton>
          <div class="more">
            <el-button type="text" @click="more('notice')">
              查看更多<i class="el-icon-d-arrow-right"></i>
            </el-button>
          </div>
        </el-card>
        <el-card shadow="never">
          <div slot="header" style="text-align: left; font-size: 13px">
            <span>系统通知</span>
            <span
              style="float: right; color: #c0c4cc; cursor: pointer"
              v-show="unreadnum > 0"
              @click="readall"
              >全部已读</span
            >
          </div>
          <el-skeleton :loading="comloading" animated :rows="2">
            <div
              v-for="(item, index) in noticecomlist"
              :key="index"
              style="text-align: left"
            >
              <el-alert
                v-if="item.priority == 'highlight' && item.readed"
                type="info"
                style="font-weight: bold"
                :closable="false"
                :description="item.content"
                @close="read(item.id)"
              >
              </el-alert>
              <el-alert
                v-else-if="item.priority == 'highlight' && !item.readed"
                type="danger"
                style="font-weight: bold"
                :description="item.content"
                @close="read(item.id)"
              >
              </el-alert>
              <el-alert
                v-else-if="item.priority == 'normal' && item.readed"
                type="info"
                :closable="false"
                :description="item.content"
                @close="read(item.id)"
              >
              </el-alert>
              <el-alert
                v-else-if="item.priority == 'normal' && !item.readed"
                type="info"
                effect="dark"
                :description="item.content"
                close-text="已读"
                @close="read(item.id)"
              >
              </el-alert>
            </div>
            <span class="noticenone" v-show="noticecomlist.length == 0"
              >暂无通知</span
            >
          </el-skeleton>
          <div class="more">
            <el-button type="text" @click="more('noticecom')"
              >查看更多<i class="el-icon-d-arrow-right"></i
            ></el-button>
          </div>
        </el-card>
      </div>

      <!-- <el-dialog title="提问" :visible.sync="questionVisible" width="40%">
        <span>填写问题详情</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="questionVisible = false">取 消</el-button>
          <el-button type="primary" @click="questionVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        wrapper: [
          {
            column: "status",
            type: "eq",
            value: 2,
          },
        ],
        orders: [
          {
            column: "updateTime",
            type: "desc",
          },
        ],
        page: {
          current: 1,
          size: 10,
        },
      },
      activeName: "first",
      wprloading: true,
      wprlist: [],
      wcploading: true,
      wcplist: [],
      projectloading: true,
      projectlist: [],
      notloading: true,
      noticelist: [],
      comloading: true,
      noticecomlist: [],
      unreadnum: 0,
      questionVisible: false,
    };
  },
  created() {
    if (sessionStorage.getItem("activeName")) {
      this.activeName = sessionStorage.getItem("activeName");
    }
    this.getProductionPage();
    this.getProblemPage();
    this.getProjectPage();
    this.getNoticePage();
    this.getNoticeComList();
  },
  watch: {
    activeName(value) {
      sessionStorage.setItem("activeName", value);
    },
  },
  methods: {
    //监听pagesize改变的事件
    // handleSizeChange(newSize) {
    //   this.queryinfo.pagesize = newSize;
    //   this.getProductionPage();
    // },
    // //监听页码值改变的事件
    // handleCurrentChange(newPage) {
    //   this.queryinfo.pagenum = newPage;
    //   this.getProductionPage();
    // },
    getProductionPage() {
      // var query = this.$sale.getEqPageQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/page", this.queryinfo)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.axios
              .post("/pms/v1/api/cust/cust/company/info")
              .then((res) => {
                var company = res.data.data;
                data.forEach((item) => {
                  item.cname = company.name;
                });
              });
            this.wprlist = data;
            this.wprloading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getProblemPage() {
      // var query = this.$sale.getEqPageQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/order/page", this.queryinfo)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.axios
              .post("/pms/v1/api/cust/cust/company/info")
              .then((res) => {
                var company = res.data.data;
                data.forEach((item) => {
                  item.cname = company.name;
                });
              });
            this.wcplist = data;
            this.wcploading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getProjectPage() {
      var queryinfo = {
        orders: [
          {
            column: "updateTime",
            type: "desc",
          },
        ],
        page: {
          current: 1,
          size: 10,
        },
      };
      this.axios
        .post("/pms/v1/api/cust/knowledge/project/main/page", queryinfo)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.projectlist = data;
            this.total = res.data.data.total;
            this.projectloading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    showDetail(id) {
      sessionStorage.setItem("activeName", "first");
      this.$router.push({
        path: "/workflow/detail",
        query: { id: id },
      });
    },
    showSub(id) {
      sessionStorage.setItem("activeName", "third");
      this.$router.push({
        path: "/konwl/project/sub",
        query: { id: id },
      });
    },
    getNoticePage() {
      var info = {
        pagenum: 1,
        pagesize: 5,
      };
      var query = this.$sale.getLogQuery(info);
      this.axios
        .post("/pms/v1/api/cust/system/noticehome/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.noticelist = data;
            this.notloading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getNoticeComList() {
      var info = {
        pagenum: 1,
        pagesize: 5,
      };
      var query = this.$sale.getLogQuery(info);
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.unreadnum = 0;
            for (let i = 0; i < data.length; i++) {
              if (!data[i].readed) {
                this.unreadnum++;
              }
            }
            this.noticecomlist = data;
            this.comloading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    read(id) {
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/read", {
          id: id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            this.getNoticeComList();
          } else {
            return false;
          }
        });
    },
    readall() {
      var idarr = [];
      for (let i = 0; i < this.noticecomlist.length; i++) {
        idarr.push(this.noticecomlist[i].id);
      }
      console.log(idarr);
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/readBatch", idarr)
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            this.getNoticeComList();
          }
        });
    },
    morewpr() {},
    morewcp() {},
    moreproject() {},
    more(tag) {
      if (tag == "wpr") {
        this.$router.push("/workflow/production");
        sessionStorage.setItem("activeName", "first");
      } else if (tag == "wcp") {
        this.$router.push("/workflow/problem");
        sessionStorage.setItem("activeName", "second");
      } else if (tag == "project") {
        this.$router.push("/konwl/project/main");
        sessionStorage.setItem("activeName", "third");
      } else if (tag == "notice") {
        this.$router.push("/notice");
        sessionStorage.setItem("activeNotice", "1");
      } else if (tag == "noticecom") {
        this.$router.push("/notice");
        sessionStorage.setItem("activeNotice", "2");
      }
    },
  },
};
</script>

<style scoped>
.home {
  width: 69%;
  text-align: left;
  float: left;
}

.home .title {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.home a:hover {
  color: #409eff;
}

.home .el-tag {
  margin-left: 15px;
}

.home .time {
  float: right;
  font-size: 13px;
  color: #c0c4cc;
  padding-right: 5px;
}

.home .info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding-top: 9px;
}

.other {
  width: 29%;
  float: right;
}

.other .el-card {
  margin-bottom: 10px;
}

.author .el-avatar {
  margin-right: 7px;
  vertical-align: middle;
}

.author .name {
  display: inline-block;
  vertical-align: middle;
}

.other .el-divider--horizontal {
  margin: 10px 0 15px 0;
}

.other .icon i {
  font-size: 25px;
  margin-bottom: 10px;
}

.other .icon span {
  font-size: 13px;
}

.other .icontitle {
  cursor: pointer;
}

.other .icon-workflow i {
  color: #409eff;
}

.other .icon-workflow:hover {
  color: #409eff;
}

.other .icon-konwl i {
  color: #e6a23c;
}

.other .icon-konwl:hover {
  color: #e6a23c;
}

.other .notice {
  font-size: 12px;
  color: #939394;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.other .el-alert {
  margin-bottom: 7px;
}

.more {
  text-align: right;
  font-size: 13px;
}

.other .noticenone {
  text-align: center;
  font-size: 14px;
  color: #909399;
}
</style>
<style>
.other .el-alert .el-alert__description {
  margin: 0;
}
</style>