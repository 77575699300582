export default {
  getPageQuery(info) {
    var query = {
      wrapper: [{
        column: info.column,
        type: "like",
        value: info.query
      }],
      orders: [{
        column: "id",
        type: "desc"
      }],
      page: {
        current: info.pagenum,
        size: info.pagesize
      },
    }
    return query;
  },

  getListQuery() {
    var query = {
      orders: [{
        column: "id",
        type: "desc"
      }],
    }
    return query;
  },

  getEqPageQuery(info) {
    var query = {
      wrapper: [{
        column: info.column,
        type: "eq",
        value: info.query
      }],
      orders: [{
        column: "id",
        type: "desc"
      }],
      page: {
        current: info.pagenum,
        size: info.pagesize
      },
    }
    return query;
  },

  getFilterQuery(info) {
    var type = 'eq';
    var status;
    if (info.status == 'all') {
      status = 1;
      type = 'ge'
    } else {
      status = info.status
    }
    var query = {
      wrapper: [{
        column: 'key',
        type: "like",
        value: info.order
      }, {
        column: "chipname",
        type: "like",
        value: info.chipname
      }, {
        column: "status",
        type: type,
        value: status
      }, {
        column: "updateTime",
        type: "ge_time",
        value: info.time[0]
      }, {
        column: "updateTime",
        type: "lt_time",
        value: this.getDay(info.time[1])
      }],
      orders: [{
        column: "id",
        type: "desc"
      }],
      page: {
        current: info.pagenum,
        size: info.pagesize
      },
    }
    return query;
  },

  getFilterList(info) {
    var type = 'eq';
    var status, column;
    if (info.status == 'all') {
      status = 1;
      type = 'ge'
    } else {
      status = info.status
    }
    if (info.order.indexOf("WPR") == 0 || info.order.indexOf("WCP") == 0) {
      column = 'orderNumber'
    } else {
      column = 'content'
    }
    var query = {
      wrapper: [{
        column: column,
        type: "like",
        value: info.order
      }, {
        column: "chipname",
        type: "like",
        value: info.chipname
      }, {
        column: "status",
        type: type,
        value: status
      }, {
        column: "type",
        type: "like",
        value: info.type
      }, {
        column: "updateTime",
        type: "ge_time",
        value: info.time[0]
      }, {
        column: "updateTime",
        type: "lt_time",
        value: this.getDay(info.time[1])
      }],
      orders: [{
        column: "id",
        type: "asc"
      }],
    }
    return query;
  },

  getProjectPage(info){
    var query = {
      wrapper: [{
        column: 'name',
        type: "like",
        value: info.name
      }, {
        column: "chipname",
        type: "like",
        value: info.chipname
      }, {
        column: "module",
        type: 'like',
        value: info.module
      }, {
        column: "type",
        type: "like",
        value: info.type
      },{
        column: "createTime",
        type: "ge_time",
        value: info.time[0]
      }, {
        column: "createTime",
        type: "lt_time",
        value: this.getDay(info.time[1])
      }],
      orders: [{
        column: "id",
        type: "desc"
      }],
      page: {
        current: info.pagenum,
        size: info.pagesize
      },
    }
    return query;
  },

  getProjectSubList(){
    var query = {
      orders: [{
        column: "createTime",
        type: "desc"
      }]
    }
    return query;
  },

  getLogQuery(info) {
    var query = {
      orders: [{
        column: "id",
        type: "desc"
      }],
      page: {
        current: info.pagenum,
        size: info.pagesize
      },
    }
    return query;
  },

  getDay(end) {
    var date = new Date(new Date(end).getTime() + 86400000);
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    return year + seperator1 + month + seperator1 + strDate;
  }
}