import Vue from "vue";
import Router from 'vue-router'
import Login from '../views/login'
import Index from '../views/index'
import Square from '../views/basic/square'
import Detail from '../views/workflow/detail'
import Info from '../views/basic/info'
import Notice from '../views/system/notice'
import WorkflowProduction from '../views/workflow/production'
import WorkflowProblem from '../views/workflow/problem'
import KonwlProjectMain from '../views/konwl/project/main'
import KonwlProjectSub from '../views/konwl/project/sub'
import notFound from '../views/404'

Vue.use(Router)

const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/index',
    component: Index,
    redirect: '/square',
    children: [{
      path: '/square',
      name: '首页',
      component: Square,
    }, {
      path: '/info',
      name: '个人中心',
      component: Info
    }, {
      path: '/notice',
      name: '公告通知',
      component: Notice
    }, {
      path: '/workflow/production',
      name: '生产质量分析',
      component: WorkflowProduction,
    }, {
      path: '/workflow/detail',
      name: '质量分析表',
      component: Detail
    }, {
      path: '/workflow/problem',
      name: '客户问题处理',
      component: WorkflowProblem,
    }, {
      path: '/konwl/project/main',
      name: '项目查看',
      component: KonwlProjectMain
    }, {
      path: '/konwl/project/sub',
      name: '文档资料查看',
      component: KonwlProjectSub
    }]
  },
  {
    path: "/404",
    name: "notFound",
    component: notFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = '杰理工程系统--' + to.name
  }
  let isLogin = localStorage.getItem('cust_token');
  // 已登录状态；当路由到login时，跳转至home
  if (to.name === '登录') {
    if (isLogin) {
      router.push({
        path: '/square'
      });
    }
  }
  next()
})

export default router


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}