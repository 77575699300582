<template>
  <div class="content production workflow">
    <p class="name">生产质量问题</p>
    <div class="top select">
      <el-button
        type="success"
        size="medium"
        icon="el-icon-edit"
        @click="createWpr()"
        >创建</el-button
      >
      <el-input
        v-model="queryinfo.order"
        placeholder="模糊查询订单"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-input
        v-model="queryinfo.chipname"
        placeholder="模糊查询芯片型号"
        clearable
        @clear="changePage"
        @change="changePage"
      ></el-input>
      <el-select
        v-model="queryinfo.status"
        @change="changePage"
        collapse-tags
        placeholder="请选择状态"
      >
        <el-option
          v-for="item in statuslist"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="queryinfo.time"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        @change="changePage"
      >
      </el-date-picker>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        @click="changePage"
        >查询</el-button
      >
    </div>
    <el-skeleton :loading="loading" animated>
      <el-card
        shadow="never"
        class="wpr pro"
        v-for="(item, index) in wprlist"
        :key="index"
      >
        <div>
          <p class="header">
            <el-button class="title" type="text" @click="showDetail(item.id)">
              {{ item.orderNumber }}——{{ item.title }}
            </el-button>
            <el-tag v-if="item.status == -1" type="info">取消</el-tag>
            <el-tag v-if="item.status == 1" type="warning">制表</el-tag>
            <el-tag v-if="item.status == 2">跟进中</el-tag>
            <el-tag v-if="item.status == 6" type="danger">延后处理</el-tag>
            <el-tag v-if="item.status == 10" type="success">完成</el-tag>
            <span class="time">{{ item.updateTime }}</span>
            <span class="btns">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                :disabled="item.status < 1 || item.status >= 10"
                @click="showEditDialog(item.id)"
                >编辑</el-button
              >
            </span>
          </p>
          <!-- <el-tag>{{ item.cname }}</el-tag> -->
          <el-tag>芯片型号：{{ item.chipname }}</el-tag>
          <el-tag type="success" v-show="item.batchNumber">
            芯片批次：{{ item.batchNumber }}
          </el-tag>
          <!-- <el-tag>订单数：{{ addComma(item.orderCount) }}</el-tag>
        <el-tag type="success"
          >生产数：{{ addComma(item.productionCount) }}</el-tag
        >
        <el-tag type="danger">不良数：{{ addComma(item.badCount) }}</el-tag>
        <el-tag type="warning">不良率：{{ item.badRate }}</el-tag>
        <el-tag type="success">样品数：{{ addComma(item.sampleCount) }}</el-tag> -->
          <el-tag v-show="item.burnerVersion">
            烧写器版本：{{ item.burnerVersion }}
          </el-tag>
          <el-tag type="warning" v-show="item.checksum">
            效验码：{{ item.checksum }}
          </el-tag>
          <el-tag v-show="item.agentName">
            代理客户：{{ item.agentName }}
          </el-tag>
          <div class="ordercontent" v-html="item.content"></div>
          <span class="annex" v-show="item.reportCustUrl">
            附件：
            <a style="color: #409eff" :href="item.reportCustUrl">客户报告</a>
          </span>
          <span class="btns footer">
            <el-button
              v-show="item.status == 1"
              type="success"
              icon="el-icon-upload2"
              size="small"
              @click="submit(item.id)"
              >提交</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-close"
              size="small"
              v-show="item.status == 1"
              @click="cancel(item.id)"
              >取消</el-button
            >
          </span>
        </div>
      </el-card>
      <el-empty description="暂无数据" v-show="wprlist.length == 0"></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :pager-count="5"
        :page-sizes="[10, 15, 20, 25, 30]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-skeleton>

    <el-dialog
      class="order"
      title="创建生产质量分析单"
      :visible.sync="addDialogVisible"
      width="60%"
      style="margin-top: -8vh"
      @close="addDialogClosed"
      :close-on-click-modal="false"
    >
      <!-- 内容主题区域 -->
      <el-form
        size="small"
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="90px"
      >
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="反馈入口:" prop="type">
              <el-select
                v-model="addForm.type"
                clearable
                placeholder="请选择反馈入口"
              >
                <el-option
                  v-for="item in typelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="代理客户:" prop="agentName">
              <el-input v-model="addForm.agentName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品型号:" prop="chipname">
              <el-select
                v-model="addForm.chipname"
                clearable
                filterable
                placeholder="请选择产品型号"
                @change="getBomInfo(addForm)"
              >
                <el-option
                  v-for="item in chiplist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="封装形式:" prop="pkgtype">
              <el-input v-model="addForm.pkgtype"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单数:" prop="orderCount">
              <el-input
                v-model="addForm.orderCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="生产数:" prop="productionCount">
              <el-input
                v-model="addForm.productionCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="样品数:" prop="sampleCount">
              <el-input
                v-model="addForm.sampleCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="不良数:" prop="badCount">
              <el-input-number
                v-model="addForm.badCount"
                controls-position="right"
                :min="0"
                :max="parseInt(addForm.productionCount)"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="不良率:" prop="badRate">
              <el-input
                v-model="addForm.badRate"
                oninput="value=value.replace(/[^\d\.]/g,'')"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="效验码:" prop="checksum">
              <el-input v-model="addForm.checksum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="烧写方法:">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="损坏环节:">
              <el-input></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="芯片批次:" prop="batchNumber">
              <el-input v-model="addForm.batchNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7"></el-col>
          <el-col :span="7"></el-col>
        </el-row>
        <el-form-item label="标题摘要:" prop="title">
          <el-input v-model="addForm.title" class="title"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="问题描述:" prop="content">
          <span>注意：请上传SDK和PCB资料</span>
          <tinymce
            v-model="addForm.content"
            ref="addcontent"
            :height="400"
          ></tinymce>
        </el-form-item>
        <el-form-item label="钉钉群:" prop="dingtalk">
          <el-input v-model="addForm.dingtalk"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" :disabled="addForm.dingtalk == ''"
          >是否推送到钉钉群</el-checkbox
        >
        <el-checkbox
          v-model="textinfo.isAtall"
          :disabled="!checked || addForm.dingtalk == ''"
          >是否@全体成员</el-checkbox
        >
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addWpr()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="order"
      title="修改生产质量分析单"
      :visible.sync="editDialogVisible"
      width="60%"
      style="margin-top: -8vh"
      @close="editDialogClosed"
      :close-on-click-modal="false"
    >
      <!-- 内容主题区域 -->
      <el-form
        size="small"
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="90px"
      >
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="反馈入口:" prop="type">
              <el-select
                v-model="editForm.type"
                clearable
                placeholder="请选择反馈入口"
              >
                <el-option
                  v-for="item in typelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品型号:" prop="chipname">
              <el-select
                v-model="editForm.chipname"
                clearable
                filterable
                placeholder="请选择产品型号"
                @change="getBomInfo(editForm)"
              >
                <el-option
                  v-for="item in chiplist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="封装形式:" prop="pkgtype">
              <el-input v-model="editForm.pkgtype"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="订单数:" prop="orderCount">
              <el-input
                v-model="editForm.orderCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="生产数:" prop="productionCount">
              <el-input
                v-model="editForm.productionCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="样品数:" prop="sampleCount">
              <el-input
                v-model="editForm.sampleCount"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="不良数:" prop="badCount">
              <el-input-number
                v-model="editForm.badCount"
                controls-position="right"
                :min="0"
                :max="parseInt(editForm.productionCount)"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="不良率:" prop="badRate">
              <el-input
                v-model="editForm.badRate"
                oninput="value=value.replace(/[^\d\.]/g,'')"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="效验码:" prop="checksum">
              <el-input v-model="editForm.checksum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="7">
            <el-form-item label="烧写方法:">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="损坏环节:">
              <el-input></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="芯片批次:" prop="batchNumber">
              <el-input v-model="editForm.batchNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="标题摘要:" prop="title">
          <el-input v-model="editForm.title" class="title"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="问题描述:" prop="content">
          <span>注意：请上传SDK和PCB资料</span>
          <tinymce
            v-if="isRefresh"
            v-model="editForm.content"
            ref="editcontent"
            :height="400"
          ></tinymce>
        </el-form-item>
        <el-form-item label="钉钉群:" prop="dingtalk">
          <el-input v-model="editForm.dingtalk"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checked" :disabled="editForm.dingtalk == ''"
          >是否推送到钉钉群</el-checkbox
        >
        <el-checkbox
          v-model="textinfo.isAtall"
          :disabled="!checked || editForm.dingtalk == ''"
          >是否@全体成员</el-checkbox
        >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editWpr()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tinymce from "@/components/Tinymce";
export default {
  components: {
    tinymce,
  },
  data() {
    return {
      queryinfo: {
        order: "",
        chipname: "",
        status: "all",
        time: [],
        //当前的页数
        pagenum: 1,
        //当前每页显示多少数据
        pagesize: 10,
      },
      statuslist: [
        {
          value: "all",
          label: "所有（不包含取消）",
        },
        {
          value: -1,
          label: "取消",
        },
        {
          value: 1,
          label: "填写",
        },
        {
          value: 2,
          label: "跟进中",
        },
        {
          value: 6,
          label: "延后处理",
        },
        {
          value: 10,
          label: "完成",
        },
      ],
      chiplist: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      typelist: [
        {
          label: "深圳反馈",
          value: "深圳反馈",
        },
        {
          label: "客户反馈",
          value: "客户反馈",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
      total: 0,
      loading: true,
      wprlist: [],
      addDialogVisible: false,
      addForm: {
        cid: "",
        type: "客户反馈",
        agentName: "",
        chipname: "",
        wafername: "",
        productline: "",
        pkgtype: "",
        batchNumber: "",
        orderCount: "",
        productionCount: "",
        sampleCount: "",
        badCount: "",
        BadRate: "",
        burnerVersion: "",
        checksum: "",
        dingtalk: "",
        title: "",
        content: "",
        workflowTime: this.getDateString(new Date()) + " 00:00:00",
      },
      addFormRules: {
        chipname: [
          {
            required: true,
            message: "请选择产品型号",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题摘要",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {},
      editFormRules: {
        chipname: [
          {
            required: true,
            message: "请选择产品型号",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题摘要",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: "blur",
          },
        ],
      },
      isRefresh: true,
      checked: true,
      textinfo: {
        content: "",
        isAtall: true,
        dingtalkAccessToken: "",
      },
    };
  },
  created() {
    if (sessionStorage.getItem("production_query")) {
      this.queryinfo = JSON.parse(sessionStorage.getItem("production_query"));
      sessionStorage.removeItem("production_query");
    } else {
      var start = new Date();
      start.setMonth(start.getMonth() - 6);
      start = this.getDateString(start);
      var end = this.getDateString(new Date());
      this.queryinfo.time = [start, end];
    }
    this.getWprPage();
  },
  methods: {
    getDateString(date) {
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getWprPage();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getWprPage();
    },
    changePage() {
      this.queryinfo.pagenum = 1;
      this.getWprPage();
    },
    getWprPage() {
      var query = this.$sale.getFilterQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.axios
              .post("/pms/v1/api/cust/cust/company/info")
              .then((ret) => {
                var company = ret.data.data;
                data.forEach((item) => {
                  item.cname = company.name;
                });
                this.wprlist = data;
                this.total = res.data.data.total;
                this.loading = false;
              });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getChipList() {
      var query = this.$sale.getListQuery();
      this.axios
        .post("/pms/v1/api/cust/bom/chipname/list", query)
        .then((res) => {
          if (res.data.code == "0") {
            console.log(res);
            var data = res.data.data;
            this.chiplist = [];
            for (var i = 0; i < data.length; i++) {
              this.chiplist.push({ label: "", value: "" });
              this.chiplist[i].label = data[i].chipname;
              this.chiplist[i].value = data[i].id;
            }
          }
        });
    },
    addComma(num) {
      if (num) {
        let reg =
          num.toString().indexOf(".") > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(\d{3})+$)/g;
        return num.toString().replace(reg, "$1,");
      } else {
        return 0;
      }
    },
    createWpr() {
      this.getChipList();
      this.addDialogVisible = true;
    },
    getBomInfo(form) {
      if (form.chipname == "") {
        form.wafername == "";
        form.productline = "";
        form.pkgtype = "";
      } else {
        var chipid = this.chiplist.find((item) => {
          //这里的chargingWorkNameList就是上面遍历的数据源
          return item.label === form.chipname;
          //筛选出匹配数据，是对应数据的整个对象
        });
        this.axios
          .post("/pms/v1/api/cust/bom/chipname/one", {
            id: chipid.value,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == "0") {
              var data = res.data.data;
              form.chipname = data.chipname;
              form.wafername = data.wafername;
              form.productline = data.productline;
              form.pkgtype = data.pkgtype;
            }
          });
      }
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
      this.$refs.addcontent.setContent("");
    },
    addWpr() {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/pms/v1/api/cust/workflow/production/order/create",
              this.addForm
            )
            .then((res) => {
              if (res.data.data) {
                this.getWprPage();
                this.$message.success("创建成功");
                if (this.checked && this.addForm.dingtalk != "") {
                  this.sendText(
                    "创建了新的生产质量分析订单《" + this.addForm.title + "》",
                    this.addForm.dingtalk
                  );
                }
                this.addDialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    cancel(id) {
      this.$confirm("此操作将取消该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .post("/pms/v1/api/cust/workflow/production/order/cancel", {
            id: id,
          })
          .then((res) => {
            if (res.data.data && res.data.code == 0) {
              this.getWprPage();
              this.statusVisible = false;
              this.$message.success("取消成功");
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },
    submit(id) {
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/submit", {
          id: id,
        })
        .then((res) => {
          if (res.data.data && res.data.code == 0) {
            this.getWprPage();
            this.statusVisible = false;
            this.$message.success("提交成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
      // this.$refs.editcontent.setContent("");
    },
    showEditDialog(id) {
      this.isRefresh = false;
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/one", {
          id: id,
        })
        .then((res) => {
          console.log(res);
          var data = res.data.data;
          this.editForm = data;
          this.getChipList();
          this.$nextTick(() => {
            this.isRefresh = true;
          });
          this.editDialogVisible = true;
        });
    },
    editWpr() {
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/pms/v1/api/cust/workflow/production/order/update",
              this.editForm
            )
            .then((res) => {
              if (res.data.data) {
                this.getWprPage();
                this.$message.success("更新成功");
                if (this.checked && this.editForm.dingtalk != "") {
                  this.sendText(
                    "更新了生产质量分析订单《" +
                      this.editForm.orderNumber +
                      "》",
                    this.editForm.dingtalk
                  );
                }
                this.editDialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    sendText(content, dingtalk) {
      this.axios.post("/pms/v1/api/cust/cust/company/info").then((res) => {
        this.textinfo.content = "客户【" + res.data.data.name + "】" + content;
        this.textinfo.dingtalkAccessToken = dingtalk;
        this.axios
          .post("/pms/v1/api/cust/system/dingtalk/sendtext", this.textinfo)
          .then((res) => {
            console.log(res);
          });
      });
    },
    showDetail(id) {
      sessionStorage.setItem(
        "production_query",
        JSON.stringify(this.queryinfo)
      );
      this.$router.push({
        path: "/workflow/detail",
        query: { id: id },
      });
    },
  },
};
</script>

<style scoped>
.el-form-item span {
  color: red;
  font-size: 8px;
}

.wpr .annex {
  float: right;
  margin-bottom: 15px;
  font-size: 14px;
}
</style>
<style>
.production .ordercontent p {
  margin: 3px 0;
}

.production .el-form-item__content {
  line-height: normal;
  text-align: left;
}
</style>