import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import sale from './utils/sale'
import JsonExcel from "vue-json-excel";

Vue.config.productionTip = false
Vue.use(ElementUI)

require('./utils/http.js')
Vue.use(VueAxios, axios)
Vue.component("downloadExcel", JsonExcel);

Vue.prototype.$sale = sale;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')