<template>
  <div class="home">
    <el-container>
      <el-header>
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          router
        >
          <img
            src="@/assets/images/top.png"
            alt=""
            height="60px"
            @click="$router.push('/square')"
            class="logo"
          />
          <el-menu-item index="/square">首页</el-menu-item>
          <!-- <el-menu-item index="/notice">公告通知</el-menu-item> -->
          <el-submenu index="/workflow">
            <template slot="title">业务模块</template>
            <el-menu-item index="/workflow/production">
              <el-badge
                :value="productnum"
                class="item"
                style="width: 100%"
                v-if="productnum > 0"
              >
                生产质量问题
              </el-badge>
              <span v-else>生产质量问题</span>
            </el-menu-item>
            <el-menu-item index="/workflow/problem">
              <el-badge
                :value="problemnum"
                class="item"
                style="width: 100%"
                v-if="problemnum > 0"
              >
                开发调试问题
              </el-badge>
              <span v-else>开发调试问题</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="/konwl">
            <template slot="title">资料模块</template>
            <el-menu-item index="/konwl/project/main">
              <span>SDK文档资料</span>
            </el-menu-item>
          </el-submenu>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <el-avatar
                v-if="info.realname"
                shape="square"
                style="background: #409eff"
                >{{ info.realname.substring(0, 1) }}
              </el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="info">个人中心</el-dropdown-item>
              <el-dropdown-item command="repass">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i
            class="el-icon-message-solid notice"
            @click="$router.push('/notice')"
          ></i>
        </el-menu>
      </el-header>
      <el-main class="contentinfo">
        <router-view></router-view>
        <el-backtop target=".contentinfo"></el-backtop>
      </el-main>
    </el-container>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="20%"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="passForm"
        status-icon
        :rules="passRules"
        ref="passFormRef"
        label-width="100px"
      >
        <el-form-item label="原密码:" prop="oldpass">
          <el-input
            type="password"
            v-model.trim="passForm.oldpass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="pass">
          <el-input
            type="password"
            v-model.trim="passForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="checkPass">
          <el-input
            type="password"
            v-model.trim="passForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatepass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog title="提问" :visible.sync="dialogVisible" width="40%">
      <span>填写问题详情</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.passForm.checkPass !== "") {
          this.$refs.passFormRef.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      query: {
        wrapper: [
          {
            column: "status",
            type: "eq",
            value: "2",
          },
        ],
      },
      productnum: 0,
      problemnum: 0,
      dialogVisible: false,
      info: {},
      passForm: {
        oldpass: "",
        pass: "",
        checkPass: "",
      },
      passRules: {
        oldpass: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCustInfo();
    this.getProductNum();
    this.getProblemNum();
  },
  methods: {
    getProductNum() {
      this.axios
        .post("/pms/v1/api/cust/workflow/production/order/count", this.query)
        .then((res) => {
          this.productnum = res.data.data;
        });
    },
    getProblemNum() {
      this.axios
        .post("/pms/v1/api/cust/workflow/problem/order/count", this.query)
        .then((res) => {
          this.problemnum = res.data.data;
        });
    },
    getCustInfo() {
      this.axios.post("/pms/v1/api/cust/cust/staff/info").then((res) => {
        this.info = res.data.data;
      });
    },
    handleCommand(command) {
      if (command == "info") {
        this.$router.push("/info");
      }
      if (command == "logout") {
        this.logout();
      } else if (command == "repass") {
        this.dialogVisible = true;
      }
    },
    logout() {
      localStorage.removeItem("cust_token");
      this.$router.push("/login");
    },
    handleClose() {
      this.$refs.passFormRef.resetFields();
    },
    updatepass() {
      this.$refs.passFormRef.validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/pms/v1/api/cust/cust/staff/updatepassword?newpassword=" +
                this.passForm.pass +
                "&oldpassword=" +
                this.passForm.oldpass +
                ""
            )
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message.success("修改成功，请重新登录");
                localStorage.removeItem("cust_token");
                this.$router.push("/login");
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-container {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #fafafa;
  overflow: hidden;
}
.el-header {
  background-color: #fff;
  box-shadow: 0 1px 3px rgb(32 26 26 / 10%);
  width: auto;
}
.el-menu {
  width: 1200px;
  margin: 0 auto;
}

.el-menu .logo {
  padding: 0;
  float: left;
  cursor: pointer;
}
.el-avatar {
  cursor: pointer;
  margin-top: 10px;
}
.el-dropdown {
  float: right;
  height: 40px;
}
.notice {
  float: right;
  font-size: 22px;
  margin: 19px 40px;
  cursor: pointer;
  color: #8590a6;
}
.el-main {
  width: 100%;
  height: calc(100vh-60px);
  margin: 0 auto;
  margin-bottom: 10px;
}
</style>