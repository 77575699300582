<template>
  <div class="notice">
    <el-skeleton :loading="loading" animated class="home">
      <div>
        <!-- <el-card shadow="never" v-for="(item, index) in noticelist" :key="index">
        <div class="title">{{ item.title }}</div>
        <span class="time" type="text">
          {{ item.createTime }}
        </span>
        <el-divider></el-divider>
        <div class="info">{{ item.content }}</div>
      </el-card> -->
        <div class="info" v-for="(item, index) in noticelist" :key="index">
          <span v-show="index > 0"><el-divider></el-divider></span>
          <p>
            <span
              class="title"
              v-if="item.priority == 'highlight'"
              style="color: #e6a23c"
              >{{ item.title }}</span
            >
            <span v-else class="title">{{ item.title }}</span>
            <span class="time">{{ item.createTime }}</span>
          </p>
          <span
            class="notice_content"
            v-if="item.priority == 'highlight'"
            style="color: #e6a23c"
            >{{ item.content }}</span
          >
          <span v-else class="content">{{ item.content }}</span>
        </div>
        <el-empty
          description="暂无公告"
          v-show="noticelist.length == 0"
        ></el-empty>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryinfo.pagenum"
          :pager-count="5"
          :page-sizes="[10, 15, 20, 25, 30]"
          :page-size="queryinfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        id: "",
        //当前的页数
        pagenum: 1,
        //当前每页显示多少数据
        pagesize: 10,
      },
      total: 0,
      loading: true,
      noticelist: [],
    };
  },
  created() {
    this.getNoticePage();
  },
  methods: {
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getNoticePage();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getNoticePage();
    },
    getNoticePage() {
      var query = this.$sale.getPageQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/system/noticehome/page", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.noticelist = data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style scoped>
.notice {
  padding-left: 25px;
}

.home {
  width: 100%;
  text-align: left;
  float: left;
}

.info {
  margin-bottom: 15px;
}

.info .title {
  font-weight: bold;
  font-size: 18px;
}

.info .time {
  float: right;
  font-size: 13px;
  color: #c0c4cc;
  padding-right: 20px;
}

.info .notice_content {
  padding-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>