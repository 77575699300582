<template>
  <div class="notice">
    <div class="top">
      <el-button
        type="text"
        @click="readGroup"
        :disabled="this.noticelist.length === 0"
        style="float: right"
        >全部已读</el-button
      >
    </div>
    <el-skeleton :loading="loading" animated>
      <div
        v-for="(item, index) in noticelist"
        :key="index"
        style="text-align: left; margin-bottom: 15px"
      >
        <el-divider class="hr" content-position="left">
          <el-badge :is-dot="!item.readed" class="item">
            <span v-if="item.priority == 'highlight'" style="color: #e6a23c">{{
              item.title
            }}</span>
            <span v-else>{{ item.title }}</span>
          </el-badge>
        </el-divider>
        <span v-if="item.priority == 'highlight'" style="color: #e6a23c">{{
          item.content
        }}</span>
        <span v-else>{{ item.content }}</span>
        <!-- <el-alert
          v-if="item.priority == 'highlight'"
          type="warning"
          :closable="false"
          :title="item.title"
          :description="item.content"
        >
        </el-alert>
        <el-alert
          v-else
          type="info"
          :closable="false"
          :title="item.title"
          :description="item.content"
        >
        </el-alert> -->
      </div>
      <el-empty
        description="暂无通知"
        v-show="noticelist.length == 0"
      ></el-empty>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :pager-count="5"
        :page-sizes="[10, 15, 20, 25, 30]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryinfo: {
        id: "",
        //当前的页数
        pagenum: 1,
        //当前每页显示多少数据
        pagesize: 10,
      },
      noticeid: [],
      total: 0,
      loading: true,
      noticelist: [],
    };
  },
  created() {
    this.getNoticePage();
    this.getNoticeList();
  },
  methods: {
    getNoticeList() {
      var query = this.$sale.getListQuery();
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/list", query)
        .then((res) => {
          if (res.data.code == "0") {
            var data = res.data.data;
            this.noticeid = [];
            for (var i = 0; i < data.length; i++) {
              if (!data[i].readed) {
                this.noticeid.push(data[i].id);
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getNoticeInfo() {
      if (this.queryinfo.id == "") {
        this.getNoticePage();
      } else {
        this.noticelist = [];
        this.queryinfo.query = "";
        this.axios
          .post("/pms/v1/api/cust/system/noticecompany/one", {
            id: this.queryinfo.id,
          })
          .then((res) => {
            var data = res.data.data;
            this.noticelist.push(data);
            this.total = 1;
          });
      }
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize;
      this.getNoticePage();
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage;
      this.getNoticePage();
    },
    getNoticePage() {
      var query = this.$sale.getPageQuery(this.queryinfo);
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/page", query)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0") {
            var data = res.data.data.records;
            this.noticelist = data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleSelectionChange(val) {
      this.sels = val;
    },
    readNotice(id) {
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/read", {
          id: id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            this.getNoticePage();
          }
        });
    },
    readGroup() {
      this.axios
        .post("/pms/v1/api/cust/system/noticecompany/readBatch", this.noticeid)
        .then((res) => {
          if (res.data.data) {
            this.getNoticePage();
          }
        });
    },
  },
};
</script>

<style scoped>
.notice {
  padding-left: 25px;
}

.top {
  text-align: left;
  width: 100%;
  margin-bottom: 7px;
}

.top .el-button {
  padding: 0;
  margin-right: 20px;
}

.notice .hr {
  display: inline-block;
}

.item {
  width: 99%;
  margin-right: 20px;
}
</style>